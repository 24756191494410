* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
}

body {

  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#root {
  display: flex;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  height: 100%;
}

.container-main {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  min-height: 100%;
  padding-top: 40px;
  max-width: 1000px;
  margin: 0 auto;
  padding-bottom: 200px;
}

@media screen and (min-width:800px) {

  .container-main.main {
    padding-left: 100px;
  }

}