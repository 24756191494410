.repo-cont {
    display: flex;
    flex-direction: column;
}

.repo-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* margin: 0 auto; */

}

.repo-item {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 20px;
    /* background-color: rgba(180, 180, 200, 0.1); */
    background-color: rgba(247,247,249);
    /* max-width: 600px; */
    font-family: Arial, Helvetica, sans-serif;
}

.repo-item h2 a {
    color: #222;
    text-decoration: none;
    font-size: 30px;

}

.repo-item h2 {
    margin: 8px 0;
}

.repo-item .desc {
    line-height: 130%;
    margin: 4px 0;
    color: #333
}

.repo-item .lang{
    margin: 4px 0;
    color: #444;
    font-size: 15px;
}

.repo-item .lang strong{
    color: #333;
}

.repo-item .visit{
    display: flex;
    color: #333;
    gap: 4px;

    width: 100%;
    margin-top: 3px;
}