* {
    -webkit-tap-highlight-color: transparent;
}

.navbar {
    display: flex;
    width: 100%;
    max-width: 800px;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    background-color: #fff;
    border-top: 1px solid rgb(204, 204, 204, 0.3);
    height: 55px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    box-shadow: 0 0 10px 3px rgb(200, 200, 200, 0.3);
}

.nav-cont {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 400px;

}

.nav-cont .navbar-links {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;

    align-items: center;
    justify-content: space-evenly;

}

.navbar-links li {
    height: 100%;
    justify-content: center;
    align-items: center;
    list-style: none;
    border-radius: 25px;
    padding: 8px;
}



li a .navicons {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 95%;
    aspect-ratio: 1/1;


}

.navbar-links li a .navicons svg {
    height: 23px;
    transition: 0.2s ease-in-out;

}


.navbar-links li a .navicons svg:hover {
    transform: translateY(-6px);

}

.activeNav a .navicons {
    /* border-bottom: 2px solid #333; */
    width: 100%;
    position: relative;
}


.activeNav a .navicons::after {
    content: "";
    position: absolute;
    height: 2px;
    border-radius: 40px;
    width: 100%;
    background-color: #333;
    transform: translateY(20px);
}

@media screen and (min-width:800px) {

    .navbar {
        position: fixed;
        left: 40px;
        top: 0;
        transform-origin: top right;
        height: 100%;
        width: 80px;
        flex-direction: column;
        justify-content: start;
    }

    .nav-cont {
        max-height: 400px;
    }


    .nav-cont .navbar-links {
        display: flex;
        flex-direction: column;
    }


    #root {
        position: relative;
    }

    .activeNav a .navicons::after {
        height: 3px;
        transform: translateY(40px);
        width: 80%;

    }


}