.container-main.contact {
    max-width: 850px;
    margin-top: 60px;

}

.formcontainer {
    padding: 40px 30px;
    border-radius: 20px;

    box-shadow: 0 4px 8px rgba(150, 150, 150, 0.1);
    background-color: rgba(247,247,249);

}

.contact-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 850px;
    margin: 0 auto;
    gap: 20px;
}


.contact-form input,
.contact-form textarea {
    font-size: 16px;
    border-radius: 10px;
    padding: 8px;
    border: none;
    border: 1px solid #ccc;
    outline: none;

}


.contact-form input:focus,
.contact-form textarea:focus {
    border: 1px solid #999;
}

.contact-form textarea {
    resize: vertical;
}

.contact-form button {
    padding: 12px 8px;
    font-size: 18px;
    cursor: pointer;
    color: #333;
    border-radius: 10px;
    border: 1px solid #ccc;
    color: #fff;
    /* background-color: rgba(150, 150, 150); */
    background-color:#acaaaa;
    transition: 0.1s ease-in-out;
}

.contact-form button:hover {
    background-color: #d3d3d3;

}


h2.cont-head {
    color: #222;
    font-size: 20px;
}
h2.cont-head-t {
    color: #222;
    font-size: 18px;
}

.social-icons-contact{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 20px;
}


.social-icons-contact .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    padding: 10px;
    border-radius: 50%;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.social-icons-contact .icon:hover {
    background-color: #eee;
    cursor: pointer;
    transform: scale(1.1);
}
