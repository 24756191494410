.container-main {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 100%;
    height: 100%;
    padding-top: 40px;
    max-width: 1000px;
    margin: 0 auto;
}

.msg {
    margin: 5px 0px;
    line-height: 1.4;
}

.container-main .msg#greet {
    font-size: 3.5em;
}

.msg.one {
    font-size: 35px;
}

.msg.two {
    font-size: 25px;
    text-align: justify;

}

.social-cont {
    display: flex;
    flex-direction: row;
    width: fit-content;
    padding: 10px 20px;
    margin: 0 auto;
    margin-top: 30px;
    border-radius: 20px;
    gap: 12px;
    background-color: #fff;
    background-color: rgba(247,247,249);
}

.social-cont a {
    font-size: 30px;
    color: rgba(56, 56, 56, 0.9);
}



.social-cont a:hover {
    font-size: 30px;
    color: rgba(56, 56, 56, 0.5);
}


.social-cont a i{
    display: inline-flex;
    width: 50px;
    aspect-ratio: 1/1;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 50%;
    background-color: #ffffff;
    border: none;

}


h2.sub-head {
    margin: 25px 10px;
    color: #222;
}

.container-secondary {
    margin-top: 40px;
    background-color: rgba(247,247,249);
    border-radius: 20px;
    padding: 20px;
}

a.view-all {
    display: flex;
    width: 100%;
    justify-content: end;
    color: blue;
}