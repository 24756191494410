.carousel-container {
    width: 100%;
    overflow-x: auto;
    scrollbar-width: thin;
    border-radius: 20px;
}


.carousel {
    display: flex;
    width: 100%;
    gap: 20px;
    scroll-snap-type: x mandatory;



}

.carousel-container-card {
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
    width: 100%;
    scroll-snap-align: start;
    padding: 10px;

}

.carousel-card {
    width: 100%;
    background: white;
    box-shadow: 0 4px 8px rgba(150, 150, 150, 0.1);
    border-radius: 15px;
    overflow: hidden;
    padding: 15px;
}

.carousel-card img {
    width: 100%;
    max-width: 100px;
    height: auto;
    border-radius: 10px;
}

.project_info h3 {
    margin-top: 10px;
    margin-bottom: 5px;
}

.project_info p {
    margin: 5px 0;
}

.project_info p.lnk {
    margin: 5px 0;
    color: blue;
    text-decoration: underline;
    font-size: 13px;
}


.repoHomeContainer {
    max-height: 400px;
    overflow-y: auto;
    border-radius: 20px;
}

.repoHomeContainer::-webkit-scrollbar {
    width: 0;
}

.repoHomeList {
    display: flex;
    flex-direction: column;
    gap: 20px;
    list-style-type: none;
    padding: 8px;
    margin: 0;
}

.repoHomeItem {
    width: 100%;
    background: white;
    box-shadow: 0 4px 8px rgba(150, 150, 150, 0.1);
    border-radius: 15px;
    overflow: hidden;
    padding: 15px;

}

.repoHomeItem h2 a{
    color: #222;
    text-decoration: none;
}
.repoHomeLoading {
    color: #999;
}

.repoHomeError {
    color: red;
}

.repoHomeDescription {
    margin-bottom: 5px;
    color: #444;
}


.repoHomeVisit {
    margin: 5px 0;
    color: blue;
    text-decoration: underline;
    font-size: 13px;
}

.repoHomeLanguages{
    color: #444;
}