/* name-cont style start ==================================================================*/
.container-name {
    display: flex;
    flex-direction: column;
    width: 100%;

    height: 160px;
    align-content: center;
    justify-content: center;
    margin: 0 auto;
    padding-left: 64px;

    margin-bottom: 40px;


}

.namecontainer-in {
    display: flex;
    flex-direction: column;
    padding: 15px 5px;
    padding-left: 115px;
    height: 90%;
    width: 100%;
    border: 2px solid transparent;
    position: relative;
    border-radius: 20px;
    /* background-color: rgba(180, 180, 200, 0.1); */
    background-color: rgba(247,247,249);
    ;
}

.namecontainer-in h2 {
    font-size: 30px;
    margin: 0;
}

.namecontainer-in p {
    font-size: 18px;
    text-align: left;
}

.avatarcontainer {
    position: absolute;
    height: 130%;
    aspect-ratio: 1/1;
    border: 2px solid #111;
    background-color: #111;
    color: #f4f4f4;
    font-size: 80px;
    left: -70px;

    top: 50%;
    transform: translate(0, -50%);


    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


/* name-cont style end ==================================================================*/


/* tool-cont style start ==================================================================*/


.cardcontainer.tool {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    min-height: 200px;
    padding: 15px;
    width: 100%;
    border-radius: 20px;
    background-color: rgba(247,247,249);

}

.toolcard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 80px;
    border-radius: 10px;
    padding: 5px;
    box-sizing: border-box;
}

.toolcard svg {
    height: 100%;
}

.toolcard svg:hover,
.toolcard p:hover+svg {
    transform: translateY(-6px);
    cursor: pointer;
    transition: transform 0.3s ease;
}

.toolcard p {
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    margin: 5px 2px;
}

h2 {
    margin: 20px 0;
    color: #111;
}

/* tool-cont style ends ==================================================================*/



/* projects-cont style start ==================================================================*/
.cardcontainer.projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 0;
    /* background-color: #fff; */
}



.projectcontainer {
    display: flex;
    width: 100%;
    border: 1px solid #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(100, 100, 100, 0.1);
    max-height: 200px;
    background-color: #fff;
}

.proj-p1 {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.project_img img {
    max-width: 100%;
    height: auto;
}

.proj-p2 {
    flex: 2;
    padding: 10px;
}

.project_title h3 {
    margin: 0;
    font-size: 1.5em;
}

.project_desc p {
    margin: 5px 0;
    font-size: 1em;
    color: #555;
}

.project_viewbtn p {
    cursor: pointer;
    color: #007bff;
    text-decoration: underline;
}

@media screen and (min-width:900px) {


    .cardcontainer.projects {
        padding: 15px;

    }

    .proj-p2 {
        flex: 2;
        padding: 20px;
    }

    .project_img img {
        max-width: 100%;
        height: auto;
        padding: 40px;

    }

    .projectcontainer {
        border: 1px solid #eee;
        box-shadow: 0 4px 8px rgba(100, 100, 100, 0.1);
    }
}

/* projects-cont style ends ==================================================================*/